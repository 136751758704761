<template>
  <Page
    has-actions
    :subtitle="
      `${total} ${$tc('result', total <= 1 ? 1 : 2)}: ${summary.stock_value &&
        summary.stock_value.formatted}`
    "
    :loading="suppliers_loading"
  >
    <div slot="actions">
      <div class="field has-addons">
        <div class="is-flex">
          <b-button
            v-if="$device.mobile"
            style="z-index: 9"
            size="is-small"
            rounded
            @click="showMobile()"
          >
            <b-icon
              icon="magnify"
              size="is-small"
            />
          </b-button>
          <b-input
            ref="search"
            v-model="search"
            :placeholder="$t('search')"
            icon="magnify"
            :size="$device.mobile ? 'is-small' : ''"
            :class="{ expand: searchFocused, 'hide-search': $device.mobile }"
            rounded
            @blur="hideMobile()"
          />
        </div>
      </div>
    </div>
    <Card
      v-if="$can('read', 'purchasing.suppliers')"
      content-class="c-table"
    >
      <div class="header-container">
        <b-select-validation
          v-model="warehouse_id"
          required
          size="is-small"
          style="width: 150px"
          hide-details
          :label="$tc('warehouse', 1)"
          @input="getSuppliers"
        >
          <template>
            <option
              v-for="opt in warehouses"
              :key="opt.id"
              :value="opt.id"
            >
              {{
                opt.country.country + " - " + opt.currency.code
              }}
            </option>
          </template>
        </b-select-validation>

        <b-field>
          <p class="control">
            <b-button
              size="is-small"
              :type="active ? 'is-primary' : ''"
              @click="active = true"
            >
              {{ $tc("active", 2) }}
            </b-button>
          </p>
          <p class="control">
            <b-button
              size="is-small"
              :type="!active ? 'is-primary' : ''"
              @click="active = false"
            >
              {{ $tc("inactive", 2) }}
            </b-button>
          </p>
        </b-field>
      </div>
      <hr class="my-0">
      <div class="columns is-mobile is-multiline is-variable is-5 m-0">
        <div
          class="column is-8-tablet is-12-mobile p-0 py-3 column-separator-right"
        >
          <b-table
            :loading="suppliers_loading"
            :data="filteredList"
            hoverable
            paginated
            pagination-position="top"
            :current-page="page"
            :per-page="perPage"
            sotrable
            :total="total"
            detailed
            custom-detail-row
            detail-key="id"
            :show-detail-icon="true"
            :has-detailed-visible="(row, index) => row.brands.length"
            default-sort-direction="asc"
            default-sort="name"
            :row-class="
              (row, index) =>
                `supplier_${row.id}${
                  row.id == updated_supplier ? ' bg-updated' : ''
                }`
            "
          >
            <template v-for="head in headers">
              <b-table-column
                :key="head.value"
                v-slot="props"
                :label="head.text"
                :field="head.value"
                :sortable="head.sortable"
                :width="head.width"
                :centered="head.centered"
                header-class="sticky-header"
              >
                <template v-if="head.value == 'stock_value.value'">
                  {{
                    props.row.stock_value
                      ? props.row.stock_value.formatted
                      : "-"
                  }}
                </template>
                <template v-else-if="head.value == 'count.products'">
                  {{ props.row.count.products }}
                </template>
                <template v-else-if="head.value == 'count.skus'">
                  {{ props.row.count.skus }}
                </template>
                <template v-else-if="head.value == 'id'">
                  <a
                    :id="`supplier_${props.row.id}`"
                    href="javascript:;"
                    class="is-clickable"
                    @click="openForm(props.index, props.row)"
                  >
                    {{ props.row.id }}
                  </a>
                </template>
                <template v-else-if="head.value == 'name'">
                  {{ props.row.name }}
                </template>
                <template v-else>
                  {{
                    props.row[head.value] != null ? props.row[head.value] : "-"
                  }}
                </template>
              </b-table-column>
            </template>

            <template
              slot="detail"
              slot-scope="props"
            >
              <tr
                v-for="item in props.row.brands"
                :key="item.label"
                class="has-text-grey"
              >
                <td />
                <td />
                <td class="pl-5">
                  {{ item.brand }}
                </td>
                <td class="has-text-centered">
                  -
                </td>
                <td class="has-text-centered">
                  {{ item.count.products }}
                </td>
                <td class="has-text-centered">
                  {{ item.count.skus }}
                </td>
                <td class="has-text-centered">
                  {{ item.stock_value ? item.stock_value.formatted : "-" }}
                </td>
                <td class="has-text-centered">
                  {{ item.itr || "-" }}
                </td>
              </tr>
            </template>

            <EmptyBlock
              slot="empty"
              icon="domain"
            />
          </b-table>
          <Pagination
            :per-page="perPage"
            :per-pages="perPages"
            :total="total"
            :current-page="page"
            @update-per-page="val => (perPage = val)"
            @update-current-page="val => (page = val)"
          />
        </div>
        <div
          id="form"
          class="column is-4-tablet is-12-mobile pt-4"
          :class="{ 'px-0': $device.mobile }"
        >
          <div class="sticky">
            <p
              class="is-size-5 pb-5 is-flex is-justify-content-space-between is-align-items-baseline"
            >
              <b>{{ title }}</b>
              <template v-if="supplier.id">
                <span
                  v-if="active && $can('delete', 'purchasing.suppliers')"
                  class="is-clickable is-size-7"
                  @click="deleteSupplier"
                >
                  <b-icon
                    icon="delete"
                    size="is-small"
                    type="is-danger"
                  />
                  {{ $t("delete") }}
                </span>
                <span
                  v-else-if="$can('delete', 'purchasing.suppliers')"
                  class="is-clickable is-size-7"
                  @click="refreshSupplier"
                >
                  <b-icon
                    icon="refresh"
                    size="is-small"
                    type="is-success"
                  />
                  {{ $t("restore") }}
                </span>
              </template>
            </p>
            <SupplierForm
              :index="index"
              :supplier="supplier"
              :warehouses="warehouses"
              :action="action"
              @updated="updateSupplierList"
              @cancel="openForm(-1, {})"
            />
          </div>
        </div>
      </div>
    </Card>
  </Page>
</template>

<script>
import debounce from "debounce";
import SupplierForm from "@/components/forms/Supplier";

export default {
  components: {
    SupplierForm
  },
  data() {
    return {
      searchFocused: false,
      loaded: false,
      suppliers_loading: true,
      active: true,
      warehouse_id: null,
      search: "",
      list: [],
      summary: {},
      filteredList: [],
      total: 0,
      page: 1,
      perPages: [50, 100, 200],
      perPage: 50,
      updated_supplier: null,
      warehouses: [],
      headers: [
        {
          text: this.$i18n.t("id"),
          value: "id",
          width: "60",
          sortable: true
        },
        {
          text: this.$i18n.tc("name", 1),
          value: "name",
          sortable: true
        },
        {
          text: this.$i18n.t("lead_time"),
          value: "lead_time",
          sortable: true,
          width: "100",
          centered: true
        },
        {
          text: this.$i18n.tc("product", 2),
          value: "count.products",
          sortable: true,
          width: "80",
          centered: true
        },
        {
          text: this.$i18n.tc("sku", 2),
          value: "count.skus",
          sortable: true,
          width: "80",
          centered: true
        },
        {
          text: this.$i18n.t("stock"),
          value: "stock_value.value",
          sortable: true,
          width: "100",
          centered: true
        },
        {
          text: this.$i18n.t("itr"),
          value: "itr",
          sortable: true,
          width: "80",
          centered: true
        }
      ],

      //   edit
      supplier: {},
      title: this.$t("new_supplier"),
      index: -1,
      action: "create"
    };
  },
  watch: {
    active() {
      this.getSuppliers();
      this.openForm(-1, {});
    },
    search: debounce(function() {
      this.filterData();
    }, 200)
  },
  created() {
    this.updateTitle(this.$tc("supplier", 2));
    this.getSuppliers();
    this.openForm(-1, {});
  },
  methods: {
    deleteSupplier() {
      this.$axios
        .delete("purchasing/suppliers/" + this.supplier.id)
        .then(() => {
          let index = this.list.findIndex(obj => obj.id == this.supplier.id);
          this.list.splice(index, 1);
          this.filterData(this.search);
          this.openForm(-1, {});
          this.$root.notify(this.$t("successfully_deleted"), "is-success");
        })
        .catch(e => this.clientError(e));
      // .finally(() => (this.suppliers_loading = false));
    },
    refreshSupplier() {
      this.$axios
        .patch("purchasing/suppliers/" + this.supplier.id + "/restore")
        .then(() => {
          let index = this.list.findIndex(obj => obj.id == this.supplier.id);
          this.list.splice(index, 1);
          this.filterData(this.search);
          this.openForm(-1, {});
          this.$root.notify(this.$t("successfully_restored"), "is-success");
        })
        .catch(e => this.clientError(e));
      // .finally(() => (this.suppliers_loading = false));
    },
    updateSupplierList(index, newSupplier) {
      let i = this.list.findIndex(s => s.id == newSupplier.id);
      if (this.active == newSupplier.active) {
        if (i != -1) {
          let supp = { ...this.list[i] };
          let id = newSupplier.warehouses.findIndex(x => x.id == this.warehouse_id); 
          supp.warehouse_id = newSupplier.warehouse_id;
          supp.name = newSupplier.name;
          supp.lead_time = newSupplier.warehouses[id].lead_time;
          supp.active = newSupplier.active;
          this.list.splice(i, 1, supp);
        } else {
          newSupplier.brands = [];
          let id = newSupplier.warehouses.findIndex(x => x.id == this.warehouse_id);  
          newSupplier.lead_time = newSupplier.warehouses[id].lead_time; 
          newSupplier.count = { products : 0,
                              skus : 0};     
          this.list.unshift(newSupplier);
          let supp = { ...this.list[i] };
          supp.lead_time = newSupplier.warehouses[id].lead_time;
        }
        this.updated_supplier = newSupplier.id;
        if (this.$device.mobile) {
          this.$scrollTo(`.supplier_${newSupplier.id}`);
        }
        setTimeout(() => {
          this.updated_supplier = null;
        }, 2000);
      } else {
        if (i != -1) {
          this.list.splice(i, 1);
        }
      }
      this.filterData();
    },
    showMobile() {
      if (this.$device.mobile) {
        this.searchFocused = true;
        this.$refs.search.focus();
      }
    },
    hideMobile() {
      if (this.$device.mobile) {
        this.searchFocused = false;
      }
    },
    getSuppliers() {
      if (!this.$can("read", "purchasing.suppliers")) return;
      this.search = "";
      this.suppliers_loading = true;
      let api_params = `?warehouse_id=${this.warehouse_id || ""}&active=${
        this.active ? 1 : 0
      }`;
      this.$axios
        .get("purchasing/suppliers" + api_params)
        .then(res => {
          this.list = res.data.list;
          this.summary = res.data.summary;
          this.filterData();
          if (!this.loaded) {
            this.warehouses = res.data.filters.warehouses;
            this.warehouse_id = this.warehouses[0] && this.warehouses[0].id;
            this.loaded = true;
          }
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.suppliers_loading = false));
    },
    filterData() {
      this.filteredList = this.list.filter(
        option =>
          option.name.toLowerCase().indexOf(this.search.toLowerCase()) != -1
      );
      this.total = this.filteredList.length;
    },
    openForm(index, supplier) {
      this.supplier = supplier;
      this.index = index;

      if (this.index != -1) {
        if (this.$can("update", "purchasing.suppliers")) {
          this.title = this.$t("edit_noun");
          this.action = "update";
        } else {
          this.title = this.$tc("supplier", 1);
          this.action = "read";
        }
        if (this.$device.mobile) {
          this.$scrollTo("#form");
        }
      } else if (this.$can("create", "purchasing.suppliers")) {
        this.title = this.$t("new_supplier");
        this.action = "create";
      } else {
        this.title = this.$t("new_supplier");
        this.action = "read";
      }
    }
  }
};
</script>
