<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit }"
    tag="div"
  >
    <form
      method="post"
      autocomplete="off"
      @submit.prevent="handleSubmit(save)"
    >
      <div class="columns is-multiline is-mobile is-variable is-2">
        <div class="column is-12">
          <b-input-validation
            v-model="supplierObj.name"
            :disabled="isShow"
            rules="required"
            :label="$tc('name', 1)"
            vid="name"
            :placeholder="placeholders.supplier.name"
          />
        </div>
        <div class="column is-12 is-size-6 mb-3">
          <b>{{ `${$t('lead_time')} (${$t('optional')})` }}</b>
        </div>
        
        <div
          v-for="opt in supplierObj.warehouses"
          :key="opt.id"
          class="column is-6"
        >
          <b-input-validation
            v-model="opt.lead_time"			  
            :label="opt.country.label + ' - ' + opt.currency.code"
            :placeholder="placeholders.number['2']"
            :disabled="isShow"
          /> 
        </div>
        

        <!-- <div class="column is-6">
          <b-select-validation
            v-model="supplierObj.warehouse_id"
            :disabled="isShow || action == 'update'"
            rules="required"
            vid="warehouse"
            :label="$tc('warehouse', 1)"
          >
            <template>
              <option
                v-for="opt in warehouses"
                :key="opt.id"
                :value="opt.id"
              >
                {{
                  opt.country.country + " - " + opt.currency.code
                }}
              </option>
            </template>
          </b-select-validation>
        </div> -->

        <!-- <div class="column is-6">
          <b-input-validation
            v-model="supplierObj.lead_time"
            :disabled="isShow"
            :label="`${$t('lead_time')} (${$t('optional')})`"
            vid="lead_time"
            :placeholder="placeholders.number['2']"
          />
        </div> -->

       
        <!-- <div class="column is-12 pt-0">
          <b-switch v-model="supplierObj.active" :disabled="isShow">{{
            $tc("active", 1)
          }}</b-switch>
        </div> -->

        <div class="column is-12">
          <b-button
            type="is-primary"
            class="mr-3"
            :disabled="save_loading || isShow"
            :loading="save_loading"
            tag="input"
            native-type="submit"
            :value="$t('save')"
          >
            {{ $t("save") }}
          </b-button>
          <b-button
            :disabled="save_loading"
            @click="$emit('cancel')"
          >
            {{
              $t("cancel")
            }}
          </b-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  props: {
    warehouses: {
      type: Array,
      required: true
    },
    index: {
      type: [Number, String],
      required: true
    },
    action: {
      type: String,
      required: true
    },
    supplier: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      save_loading: false,
      supplierObj: {
        warehouse_id: null,
        name: "",
        lead_time: null,
        active: true,
        warehouses: []
      }
    };
  },
  computed: {
    isShow() {
      return this.action == "read";
    }
  },
  watch: {
    supplier(newVal) {
      if (newVal.id) {
        this.supplierObj = {
          warehouse_id: newVal.warehouse_id,
          name: newVal.name,
          lead_time: newVal.lead_time,
          warehouses: newVal.warehouses,
        };
      } else {
        this.supplierObj = {
          warehouse_id: null,
          name: null,
          lead_time: null,
          active: true,
          warehouses: [],
        };
        for (let i = 0; i < this.warehouses.length; i++) {
          let arr = { id: this.warehouses[i].id, 
            country : {label: this.warehouses[i].country.country},
            currency : {code : this.warehouses[i].currency.code}, 
            lead_time: null,}
          this.supplierObj.warehouses.push(arr);  
        }

      }
      this.$refs.observer.reset();
    }
  },
  created() {
    this.supplier = {};
  },
  methods: {
    save() {
      this.save_loading = true;

      let method = this.action == "create" ? "POST" : "PUT";
      let url = this.action == "create" ? "" : `/${this.supplier.id}`;
      let data = {
        name: this.supplierObj.name,
        warehouses: [],
      };
      if (this.action == "create") data.active = true;
      for (let i = 0; i < this.supplierObj.warehouses.length; i++) {
        let obj = {
          id: this.supplierObj.warehouses[i].id,
          lead_time: this.supplierObj.warehouses[i].lead_time,
        }
        data.warehouses.push(obj);
      }
      this.$axios({
        method: method,
        url: "purchasing/suppliers" + url,
        data: data
      })
        .then(res => {
          this.$emit("updated", this.index, res.data);
          if (this.supplier && this.supplier.id) {
            this.$root.notify(this.$t("successfully_updated"), "is-success");
          } else {
            this.$root.notify(this.$t("successfully_created"), "is-success");
          }
        })
        .catch(e => this.clientError(e))
        .finally(() => {
          this.save_loading = false;
          this.$emit("cancel");
        });
    }
  }
};
</script>

